import React, { useState } from "react";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";

const LaunchScreen = () => {
  const [showSignIn, setShowSignIn] = useState(true);

  const toggleForm = () => {
    setShowSignIn(!showSignIn);
  };

  return (
    <div
      className="flex flex-col min-h-screen font-customFont"
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("./launchbg.png")`,
        backgroundSize: "60%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)",
      }}
    >
      <div className="bg-white lg:bg-transparent sticky top-0 left-0 right-0 z-20 flex items-center px-4 py-3">
        <img src="../logo.png" className="w-[50px]" />
        <h1 className="text-3xl font-bold text-gray-800">Expense</h1>
      </div>

      <div className="flex flex-col lg:flex-row items-center flex-1 px-6 lg:px-16 py-12 lg:py-16 space-y-8 lg:space-y-0 lg:space-x-10 z-10">
        <div className="w-full lg:w-1/2 text-center lg:text-left mb-20 lg:ms-20">
          <h1 className="text-6xl font-bold text-gray-900">
            Predictable growth starts here.
          </h1>
          <p className="text-md text-black mt-5">
            Track every project’s expenses seamlessly! With team roles,
            multi-project management, and end-of-month email summaries, managing
            your finances has never been easier.
          </p>
        </div>
        <div className="w-full lg:w-1/2">
          {showSignIn ? (
            <SignIn toggleForm={toggleForm} />
          ) : (
            <SignUp toggleForm={toggleForm} />
          )}
        </div>
      </div>

      <div className="bg-white lg:bg-transparent sticky bottom-0 left-0 right-0 p-4 z-10">
        <div className="flex gap-5 justify-center">
          <p className="text-themeColor1 font-semibold text-sm">
            Ⓒ 2024 xPense
          </p>
          <p className="text-black font-semibold text-sm">Terms of Service</p>
          <p className="text-black font-semibold text-sm">Privacy Policy</p>
          <p className="text-black font-semibold text-sm">Support</p>
        </div>
      </div>
    </div>
  );
};

export default LaunchScreen;
