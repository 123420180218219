import React, { useState, useEffect } from "react";
import "../App.css";
import { toast } from "react-toastify";
import ProjectCards from "../components/ProjectCards";
import axios from "axios";
import WeeklyExpense from "../components/WeeklyExpense";
import YearlyExpense from "../components/YearlyExpense";
import MonthlyExpense from "../components/MonthlyExpense";
import { Link } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "PKR":
      return "Rs";
    case "SAR":
      return "﷼";
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    case "AED":
      return "AED";
    default:
      return currency;
  }
};

const Dashboard = () => {
  const token = localStorage.getItem("token");
  const baseCurrency = localStorage.getItem("baseCurrency");
  const [duration, setDuration] = useState("last12months");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [project, setProject] = useState("All");
  const [projects, setProjects] = useState([]);
  const [userProject, setUserProject] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isSubUser, setIsSubUser] = useState(true);
  const [expense, setExpense] = useState({
    totalIncome: 0,
    totalExpense: 0,
    filteredExpenses: [],
  });

  const handleProjectChange = (value) => setProject(value);
  const handleDurationChange = (value) => setDuration(value);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: userProfile } = await axios.get(
        `${API_BASE_URL}/auth/profile`,
        config
      );
      setUserProject("All");
      setIsSubUser(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          const { data: subUserProfile } = await axios.get(
            `${API_BASE_URL}/subUsers/subUserProfile`,
            config
          );
          setUserProject(subUserProfile.project || "");
          setUserRole(subUserProfile.role || "");
          setIsSubUser(true);

          if (subUserProfile.role === "admin") {
            setUserProject("All");
          }
        } catch (subUserError) {
          console.error(subUserError);
        }
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/projects/getProjectList`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        toast.error("Failed to load projects");
      }
    };

    fetchProjects();
    fetchUserProfile();
  }, []);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchFilteredExpenses = async (selectedProject, selectedDuration) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/expenses/filter`, {
        params: { selectedProject, selectedDuration },
        headers: { Authorization: `Bearer ${token}` },
      });
      setExpense(response.data);
    } catch (error) {
      console.error("Error fetching filtered expenses:", error);
      setError("Failed to load expense data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFilteredExpenses("All", "last12months");
  }, []);

  useEffect(() => {
    fetchFilteredExpenses(project, duration);
  }, [project, duration]);

  useEffect(() => {
    if (!isSubUser || userRole === "Admin") {
      setProject("All");
    } else {
      setProject(userProject);
    }
  }, [isSubUser, userRole, userProject]);

  const handleRefresh = () => {
    if (!isSubUser || userRole === "Admin") {
      setProject("All");
      setDuration("last12months");
    } else {
      setProject(userProject);
      setDuration("last12months");
    }
    fetchFilteredExpenses(project, duration);
  };
  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (!isSubUser || userRole === "Admin") {
      setProject();
    } else {
      setProject(userProject);
    }
  }, [isSubUser, userRole, userProject]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <div class="spinner"></div>
      </div>
    );
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="flex flex-col gap-2 h-full bg-white rounded-xl py-3 px-4 border border-2 border-[#E9EAEB] space-y-2">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <div
          style={{ fontFamily: "Roboto, sans-serif" }}
          className="mb-4 md:mb-0"
        >
          <h1 className="font-bold text-lg">Dashboard</h1>
          <p className="text-xs text-gray-500 font-semibold">
            View and manage your expenses
          </p>
        </div>

        <div className="flex gap-2 items-center">
          <button
            onClick={handleRefresh}
            className="text-black border border-[#DCE3E3] px-3 py-1 rounded-md"
          >
            <i className="fa-solid fa-arrow-rotate-right text-lg"></i>
          </button>
          {(!isSubUser ||
            (isSubUser && (userRole === "Edit" || userRole === "Admin"))) && (
            <Link to="/addExpense">
              <button className="bg-themeGradient text-white px-3 sm:px-4 py-1 rounded-md flex items-center">
                <i className="fa-solid fa-plus text-lg"></i>
                <span className="ms-2 text-sm sm:text-base">
                  New Transaction
                </span>
              </button>
            </Link>
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 w-full text-center mb-4 font-customFont">
        <div className="border border-[#AFF0CC] bg-[#EDFFF5] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#006B31] text-md">Total Income</p>
          <p className="text-[#002410] text-3xl font-bold mt-1">
            {getCurrencySymbol(baseCurrency)}{" "}
            <span>{Math.floor(expense.totalIncome).toLocaleString()}</span>
            <span className="text-[#55AA55] text-xl">
              .{expense.totalIncome.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
        <div className="relative border border-[#FFE5E3] bg-[#FFF5EF] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#B51D1D] text-md">Total Expense</p>
          <p className="text-[#7C1A1D] text-3xl font-bold mt-1">
            {getCurrencySymbol(baseCurrency)}{" "}
            <span>{Math.floor(expense.totalExpense).toLocaleString()}</span>
            <span className="text-[#D65555] text-xl">
              .{expense.totalExpense.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
        <div className="border border-[#CCD7FF] bg-[#E5EBFF] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#002199] text-md">Total Net Income</p>
          <p className="text-[#000B33] text-3xl font-bold mt-1">
            {getCurrencySymbol(baseCurrency)}{" "}
            <span>
              {Math.floor(
                expense.totalIncome - expense.totalExpense
              ).toLocaleString()}
            </span>
            <span className="text-[#555588] text-xl">
              .
              {
                (expense.totalIncome - expense.totalExpense)
                  .toFixed(2)
                  .split(".")[1]
              }
            </span>
          </p>
        </div>
      </div>

      <div className="w-full rounded-lg border border-[#E5E7EB] shadow-sm p-3">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 md:gap-0">
          <div>
            <p className="font-semibold font-customFont">Overview</p>
          </div>

          <div className="flex flex-col sm:flex-row gap-2 w-full md:w-auto font-customFont">
            <Select
              defaultValue="All"
              onChange={handleProjectChange}
              value={project}
              required
              className="w-full sm:w-auto lg:w-60 ring-1 ring-blue-400 rounded-md"
            >
              {(isSubUser && userRole === "Admin") || !isSubUser ? (
                <>
                  <Option value="All">All Projects</Option>
                  {projects.map((pro) => (
                    <Option key={pro._id} value={pro.project}>
                      {pro.project}
                    </Option>
                  ))}
                </>
              ) : (
                <Option value={userProject}>{userProject}</Option>
              )}
            </Select>

            <Select
              defaultValue="Last12Months"
              required
              value={duration}
              onChange={handleDurationChange}
              onBlur={() => {
                if (!duration) setDuration("");
              }}
              className={`w-full sm:w-auto lg:w-60 ${
                duration ? "ring-1 ring-blue-400 rounded-md" : ""
              }`}
            >
              <Option value="currentweek">This Week</Option>
              <Option value="last12months">Last 12 Months</Option>
              <Option value="last6years">Last 6 Years</Option>
            </Select>
          </div>
        </div>

        <div>
          {duration === "last6years" && (
            <YearlyExpense
              selectedProject={project}
              selectedDuration={duration}
            />
          )}
          {duration === "last12months" && (
            <MonthlyExpense
              selectedProject={project}
              selectedDuration={duration}
            />
          )}
          {duration === "currentweek" && (
            <WeeklyExpense
              selectedProject={project}
              selectedDuration={duration}
            />
          )}
        </div>
      </div>
      <div>
        <ProjectCards />
      </div>
    </div>
  );
};

export default Dashboard;
