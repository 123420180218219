import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import SiderProjectList from "./SiderProjectList";
import LoggedInUser from "./LoggedInUser";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Sidebar = ({ setActivePage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");
  const [isSubUser, setIsSubUser] = useState(true);
  const [isFromAuthProfile, setIsFromAuthProfile] = useState(false);

  useEffect(() => {
    const path = location.pathname.replace("/", "") || "/";
    setActivePage(path);
  }, [location, setActivePage]);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: userProfile } = await axios.get(
        `${API_BASE_URL}/auth/profile`,
        config
      );
      setUserRole(userProfile.role || "Admin");
      setIsSubUser(false);
      setIsFromAuthProfile(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          const { data: subUserProfile } = await axios.get(
            `${API_BASE_URL}/subUsers/subUserProfile`,
            config
          );
          setUserRole(subUserProfile.role || "");
          setIsSubUser(true);
        } catch (subUserError) {
          toast.error("Failed to fetch sub-user data");
          console.error(subUserError);
        }
      } else {
        toast.error("Failed to fetch user profile");
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const getIcon = (path, icon, activeIcon) => {
    return location.pathname === path ? activeIcon : icon;
  };

  return (
    <div className="bg-[#EDF1F1] h-full w-[250px] fixed font-customFont">
      <div className="flex items-center justify-start ms-3 mt-3">
        <img src="/logo.png" className="w-[40px]" alt="Logo" />
        <p className="text-black font-bold text-2xl ml-1">Expense</p>
      </div>

      <div className="mt-2 ms-3 pb-2 border-b-2 border-[#D5D7DA]">
        {isSubUser ||
        userRole === "View" ||
        userRole === "Admin" ||
        userRole === "All" ? (
          <div
            className={`flex items-center p-2 pl-2 cursor-pointer ${
              location.pathname === "/dashboard"
                ? "bg-white text-black rounded-md shadow-sm"
                : "text-black"
            }`}
            onClick={() => navigate("/dashboard")}
          >
            <img
              src={getIcon(
                "/dashboard",
                "/icons/Dashboard.svg",
                "/icons/DashboardActive.svg"
              )}
              alt="Dashboard icon"
              className="mr-3 w-5 h-5"
            />
            <span className="flex-1">Dashboard</span>
          </div>
        ) : null}
        {isSubUser ||
        userRole === "View" ||
        userRole === "Edit" ||
        userRole === "Admin" ||
        userRole === "All" ? (
          <div
            className={`flex items-center p-2 pl-2 cursor-pointer ${
              location.pathname === "/expenseList"
                ? "bg-white text-black rounded-md shadow-sm"
                : "text-black"
            }`}
            onClick={() => navigate("/expenseList")}
          >
            <img
              src={getIcon(
                "/expenseList",
                "/icons/Expenses.svg",
                "/icons/ExpensesActive.svg"
              )}
              alt="Expense List icon"
              className="mr-3 w-5 h-5"
            />
            <span className="flex-1">Expenses</span>
          </div>
        ) : null}
        <div
          className={`flex items-center p-2 pl-2 cursor-pointer ${
            location.pathname === "/chats"
              ? "bg-white text-black rounded-md shadow-sm"
              : "text-black"
          }`}
          onClick={() => navigate("/chats")}
        >
          <img
            src={getIcon("/chats", "/icons/Chat.svg", "/icons/ChatActive.svg")}
            alt="Chat icon"
            className="mr-3 w-5 h-5"
          />
          <span className="flex-1">Chat</span>
        </div>
        {userRole === "Admin" || userRole === "All" ? (
          <div
            className={`flex items-center p-2 pl-2 cursor-pointer ${
              location.pathname === "/projects"
                ? "bg-white text-black rounded-md shadow-sm"
                : "text-black"
            }`}
            onClick={() => navigate("/projects")}
          >
            <img
              src={getIcon(
                "/projects",
                "/icons/Projects.svg",
                "/icons/ProjectsActive.svg"
              )}
              alt="Project icon"
              className="mr-3 w-5 h-5"
            />
            <span className="flex-1">Projects</span>
          </div>
        ) : null}
        {isFromAuthProfile ? (
          <div
            className={`flex items-center p-2 pl-2 cursor-pointer ${
              location.pathname === "/team"
                ? "bg-white text-black rounded-md shadow-sm"
                : "text-black"
            }`}
            onClick={() => navigate("/team")}
          >
            <img
              src={getIcon("/team", "/icons/Team.svg", "/icons/TeamActive.svg")}
              alt="Team icon"
              className="mr-3 w-5 h-5"
            />
            <span className="flex-1">Team</span>
          </div>
        ) : null}
        {userRole === "Admin" || userRole === "All" ? (
          <div
            className={`flex items-center p-2 pl-2 cursor-pointer ${
              location.pathname === "/invoice"
                ? "bg-white text-black rounded-md shadow-sm"
                : "text-black"
            }`}
            onClick={() => navigate("/invoice")}
          >
            <img
              src={getIcon(
                "/invoice",
                "/icons/Invoice.svg",
                "/icons/InvoiceActive.svg"
              )}
              alt="Invoice icon"
              className="mr-3 w-5 h-4"
            />
            <span className="flex-1">Invoice</span>
          </div>
        ) : null}
      </div>

      <SiderProjectList />

      <div className="absolute bottom-0 left-0 w-full p-2 pl-3 flex items-center cursor-pointer text-gray-700">
        <LoggedInUser />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  setActivePage: PropTypes.func.isRequired,
};

export default Sidebar;
