import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Button, Input, Upload, Empty, Popconfirm } from "antd";
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const EditInvoice = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState("");
  const [customerCompany, setCustomerCompany] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [discount, setDiscount] = useState(0);
  const [note, setNote] = useState("");
  const [taxList, setTaxList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [taxName, setTaxName] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemQuantity, setItemQuantity] = useState(1);
  const [itemUnitPrice, setItemUnitPrice] = useState(0);
  const [signature, setSignature] = useState(null);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isTaxModalVisible, setIsTaxModalVisible] = useState(false);
  const [isItemModalVisible, setIsItemModalVisible] = useState(false);

  const fetchInvoiceDetails = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/invoice/getInvoiceById/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      setCustomerName(data.customerName);
      setCustomerCompany(data.customerCompany);
      setInvoiceDate(data.invoiceDate);
      setCurrencySymbol(data.currencySymbol);
      setDiscount(data.discount || 0);
      setNote(data.note || "");
      setTaxList(data.taxes || []);
      setItemList(data.items || []);
      setSignature(data.signature || null);
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, [id]);

  const handleAddTax = () => {
    if (!taxName || !taxRate) return;
    setTaxList([...taxList, { name: taxName, rate: parseFloat(taxRate) }]);
    setTaxName("");
    setTaxRate("");
    setIsTaxModalVisible(false);
  };

  const handleDeleteTax = (index) => {
    setTaxList(taxList.filter((_, i) => i !== index));
  };
  const handleDeleteItem = (index) => {
    const updatedItemList = itemList.filter((_, i) => i !== index);
    setItemList(updatedItemList);
  };

  const handleAddItem = () => {
    if (!itemName || itemQuantity <= 0 || itemUnitPrice <= 0) return;
    setItemList([
      ...itemList,
      {
        itemName,
        itemQuantity: parseInt(itemQuantity, 10),
        itemUnitPrice: parseFloat(itemUnitPrice),
        itemTotalPrice: parseFloat(itemQuantity) * parseFloat(itemUnitPrice),
      },
    ]);
  };

  const uploadImage = async (image) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "ExpenseTracker");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dqvgbgje8/image/upload",
        {
          method: "PUT",
          body: data,
        }
      );
      const urlData = await response.json();
      return urlData.url;
    } catch (error) {
      console.error(error);
      toast.error("Image upload failed.");
      return null;
    }
  };

  const handleFileChange = ({ file }) => {
    if (file.status === "done") {
      setSignature(file.originFileObj);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaveLoader(true);

    let signatureUrl = null;
    if (signature) {
      signatureUrl = await uploadImage(signature);
      if (!signatureUrl) {
        toast.error("Failed to upload signature. Please try again.");
        return;
      }
    } else {
      signatureUrl = "";
    }

    const updatedInvoice = {
      customerName,
      customerCompany,
      invoiceDate,
      currencySymbol,
      discount,
      note,
      taxes: taxList.map((tax) => ({
        name: tax.name,
        rate: tax.rate,
        taxId: tax.id || null,
      })),
      items: itemList.map((item) => ({
        itemId: item._id || item.itemId || null,
        itemName: item.itemName,
        itemQuantity: item.itemQuantity,
        itemUnitPrice: item.itemUnitPrice,
        itemTotalPrice: item.itemTotalPrice,
      })),
      signature: signatureUrl,
    };

    try {
      const response = await axios.put(
        `${API_BASE_URL}/invoice/updateInvoice/${id}`,
        updatedInvoice,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Invoice updated successfully!");
      navigate("/Panel/invoice");
    } catch (error) {
      toast.error("Failed to update invoice");
    } finally {
      setSaveLoader(false);
    }
  };

  return (
    <div className="rounded-xl bg-white h-full p-4 border border-2 border-[#E9EAEB] font-customFont">
      <h1 className="font-bold text-xl mb-4">Edit Invoice</h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Left Column */}
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Customer Name:
                <input
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Customer Company:
                <input
                  value={customerCompany}
                  onChange={(e) => setCustomerCompany(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Invoice Date:
                <input
                  type="date"
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>
          </div>

          {/* Right Column */}
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Currency:
                <select
                  value={currencySymbol}
                  onChange={(e) => setCurrencySymbol(e.target.value)}
                  className="mt-1 block w-full px-3 py-2.5 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  <option value="">Select Currency</option>
                  <option value="$">$</option>
                  <option value="£">£</option>
                  <option value="€">€</option>
                  <option value="﷼">﷼</option>
                  <option value="Rs">Rs</option>
                </select>
              </label>
            </div>
            <div className="mb-4">
              <label>
                Discount:
                <input
                  type="number"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>
            <div className="">
              <label className="block text-gray-700 mb-2">
                Signature:
                <Upload
                  listType="picture"
                  onChange={handleFileChange}
                  maxCount={1}
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                  className="mt-1 block w-full py-0 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="mb-1 bg-transparent border-0 hover:!text-themeColor hover:!bg-transparent hover:text-themeColor2 focus:outline-none"
                  >
                    Upload Signature
                  </Button>
                </Upload>
              </label>
              {signature && (
                <div className="mt-2">
                  <img
                    src={signature}
                    alt="Signature"
                    className="w-32 h-20 object-contain"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Items */}
        <h2 className="font-bold text-lg mt-6">Items</h2>
        <Button onClick={() => setIsItemModalVisible(true)}>
          Add Item <PlusOutlined />
        </Button>

        <div className="relative mt-4">
          <div className="max-h-[500px] scrollbar-hide">
            <div className="overflow-x-auto overflow-hidden rounded-lg border border-gray-200">
              <div className="min-w-[800px] sm:min-w-full rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#E9EAEB] text-[#535862] font-semibold border border-gray-200">
                    <tr>
                      <th className="px-4 py-3">Name</th>
                      <th className="px-4 py-3">Quantity</th>
                      <th className="px-4 py-3">Unit Price</th>
                      <th className="px-4 py-3">Total Price</th>
                      <th className="px-4 py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemList.length > 0 ? (
                      itemList.map((item, index) => (
                        <tr
                          key={index}
                          className="hover:bg-gray-50 transition-all duration-300 text-center border-b border-gray-200"
                        >
                          <td className="px-4 py-3">{item.itemName}</td>
                          <td className="px-4 py-3">{item.itemQuantity}</td>
                          <td className="px-4 py-3">{item.itemUnitPrice}</td>
                          <td className="px-4 py-3">{item.itemTotalPrice}</td>
                          <td className="px-4 py-3">
                            <div className="flex gap-1 justify-center items-center">
                              <Popconfirm
                                title="Are you sure you want to delete this item?"
                                onConfirm={() => handleDeleteItem(index)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <DeleteOutlined className="text-red-500 cursor-pointer" />
                              </Popconfirm>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="py-10">
                          <Empty description="No Items found" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Taxes */}
        <h2 className="font-bold text-lg mt-6">Taxes</h2>
        <Button
          onClick={() => setIsTaxModalVisible(true)}
          className="hover:text-themeColor hover:border-themeColor"
        >
          Add Tax <PlusOutlined />
        </Button>
        <div className="relative mt-4">
          <div className="max-h-[500px] scrollbar-hide">
            <div className="overflow-x-auto overflow-hidden rounded-lg border border-gray-200">
              <div className="min-w-[800px] sm:min-w-full rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#E9EAEB] text-[#535862] font-semibold border border-gray-200">
                    <tr>
                      <th className="px-4 py-3">Name</th>
                      <th className="px-4 py-3">Rate</th>
                      <th className="px-4 py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxList.length > 0 ? (
                      taxList.map((tax, index) => (
                        <tr
                          key={index}
                          className="hover:bg-gray-50 transition-all duration-300 text-center border-b border-gray-200"
                        >
                          <td className="px-4 py-3">{tax.name}</td>
                          <td className="px-4 py-3">{tax.rate}</td>
                          <td className="px-4 py-3">
                            <div className="flex gap-1 justify-center items-center">
                              <Popconfirm
                                title="Are you sure you want to delete this item?"
                                onConfirm={() => handleDeleteTax(index)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <DeleteOutlined className="text-red-500 cursor-pointer" />
                              </Popconfirm>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="py-10">
                          <Empty description="No Taxes found" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <label className="font-bold text-lg ">Note:</label>
          <TextArea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="mb-4"
          />
        </div>

        <div className="flex justify-center mt-5">
          <Button
            type="primary"
            htmlType="submit"
            loading={saveLoader}
            className="py-2 px-6 w-40 bg-themeGradient text-white font-semibold rounded-md shadow-sm hover:bg-themeColor2"
          >
            Update Invoice
          </Button>
        </div>
      </form>

      {/* Add Item Modal */}
      <Modal
        title="Add Item"
        visible={isItemModalVisible}
        onOk={handleAddItem}
        onCancel={() => setIsItemModalVisible(false)}
      >
        <label>Item Name:</label>
        <Input
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          className="mb-4"
        />
        <label>Quantity:</label>
        <Input
          type="number"
          value={itemQuantity}
          onChange={(e) => setItemQuantity(e.target.value)}
          className="mb-4"
        />
        <label>Unit Price:</label>
        <Input
          type="number"
          value={itemUnitPrice}
          onChange={(e) => setItemUnitPrice(e.target.value)}
          className="mb-4"
        />
      </Modal>

      {/* Add Tax Modal */}
      <Modal
        title="Add Tax"
        visible={isTaxModalVisible}
        onOk={handleAddTax}
        onCancel={() => setIsTaxModalVisible(false)}
      >
        <label>Tax Name:</label>
        <Input
          value={taxName}
          onChange={(e) => setTaxName(e.target.value)}
          className="mb-4"
        />
        <label>Tax Rate (%):</label>
        <Input
          type="number"
          value={taxRate}
          onChange={(e) => setTaxRate(e.target.value)}
          className="mb-4"
        />
      </Modal>
    </div>
  );
};

export default EditInvoice;
