import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const getCurrentWeekDays = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek);

  const weekDays = [];
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  for (let i = 0; i < 7; i++) {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + i);
    weekDays.push(dayNames[day.getDay()]);
  }

  return weekDays;
};

const WeeklyExpense = ({ selectedProject, selectedDuration }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expenseData, setExpense] = useState({
    totalIncome: 0,
    totalExpense: 0,
    filteredExpenses: [],
  });
  const [showIncome, setShowIncome] = useState(true);
  const [showExpense, setShowExpense] = useState(true);
  const [showProfit, setShowProfit] = useState(true);

  const fetchFilteredExpenses = async (selectedProject, selectedDuration) => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.get(`${API_BASE_URL}/expenses/filter`, {
        params: { selectedProject, selectedDuration },
        headers: { Authorization: `Bearer ${token}` },
      });

      const currentWeekDays = getCurrentWeekDays();

      const groupedData = currentWeekDays.reduce((acc, day) => {
        acc[day] = { totalIncome: 0, totalExpense: 0, profit: 0 };
        return acc;
      }, {});

      response.data.filteredExpenses.forEach((expense) => {
        const weekDay = new Date(expense.date).toLocaleString("en-EN", {
          weekday: "long",
        });
        if (groupedData[weekDay]) {
          if (expense.type === "Income") {
            groupedData[weekDay].totalIncome += expense.baseCurrencyPrice;
          } else if (expense.type === "Expense") {
            groupedData[weekDay].totalExpense += expense.baseCurrencyPrice;
          }
          groupedData[weekDay].profit = Math.max(
            groupedData[weekDay].totalIncome -
              groupedData[weekDay].totalExpense,
            0
          );
        }
      });

      const formattedData = currentWeekDays.map((day) => ({
        day,
        totalIncome: groupedData[day].totalIncome,
        totalExpense: groupedData[day].totalExpense,
        profit: groupedData[day].profit,
      }));

      setExpense({
        ...response.data,
        filteredExpenses: formattedData,
      });
    } catch (error) {
      console.error(
        "Error fetching or processing data:",
        error.message,
        error.response
      );
      setError("Failed to load expense data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFilteredExpenses(selectedProject, selectedDuration);
  }, [selectedProject, selectedDuration]);

  const handleIncomeChange = () => setShowIncome((prev) => !prev);
  const handleExpenseChange = () => setShowExpense((prev) => !prev);
  const handleProfitChange = () => setShowProfit((prev) => !prev);

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <div className="spinner"></div>
      </div>
    );

  if (error) return <p>Error: {error}</p>;

  const showGraph = showIncome || showExpense || showProfit;

  return (
    <div className="rounded-lg bg-white p-4 lg:h-full font-customFont">
      <div className="flex">
        <label className="flex items-center me-2">
          <input
            type="checkbox"
            checked={showIncome}
            onChange={handleIncomeChange}
            className="custom-radioIncome me-1"
          />
          <span className="text-black">Total Income</span>
        </label>
        <label className="flex items-center me-2">
          <input
            type="checkbox"
            checked={showExpense}
            onChange={handleExpenseChange}
            className="custom-radioExpense me-1"
          />
          <span className="text-black">Total Expense</span>
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={showProfit}
            onChange={handleProfitChange}
            className="custom-radioNetIncome me-1"
          />
          <span className="text-black">Total Net Income</span>
        </label>
      </div>

      {expenseData.filteredExpenses.length === 0 ? (
        <p>No expenses available for the selected duration.</p>
      ) : (
        <>
          {!showGraph ? (
            <p className="mt-2">
              Please select at least one metric to display.
            </p>
          ) : (
            <BarChart
              dataset={expenseData.filteredExpenses}
              xAxis={[{ scaleType: "band", dataKey: "day" }]}
              yAxis={[{ scaleType: "linear" }]}
              series={[
                showIncome
                  ? {
                      dataKey: "totalIncome",
                      valueFormatter: (value) => `$${value.toFixed(2)}`,
                      color: "#4CAF50",
                    }
                  : null,
                showExpense
                  ? {
                      dataKey: "totalExpense",
                      valueFormatter: (value) => `$${value.toFixed(2)}`,
                      color: "#FF5733",
                    }
                  : null,
                showProfit
                  ? {
                      dataKey: "profit",
                      valueFormatter: (value) => `$${value.toFixed(2)}`,
                      color: "#3F51B5",
                    }
                  : null,
              ].filter(Boolean)}
              layout="vertical"
              margin={{ right: 30, left: 80 }}
              height={350}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WeeklyExpense;
