import React, { useState } from "react";
import axios from "axios";
import { Modal } from "antd"; // Import Ant Design Modal
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const ImportBulkExpenses = () => {
  const [file, setFile] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("token");
    e.preventDefault();

    if (!file) {
      alert("Please select a file!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/expenses/bulkImport`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.message);
      setFile(null);
      setIsModalVisible(false);
      setLoading(false);
    } catch (error) {
      console.error(error);

      if (error.response) {
        toast.error(error.response.data.message || "Error uploading data");
      } else if (error.code === "ERR_NETWORK") {
        toast.error("Network error. Please check your connection.");
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <div className="spinner"></div>
      </div>
    );

  return (
    <div>
      <button
        className="bg-redGradient text-white px-2 py-2 rounded-md mb-4 md:mb-0 flex items-center"
        onClick={showModal}
      >
        <i className="fa-solid fa-download text-md"></i>
        <span className="ms-2">Bulk Import</span>
      </button>

      <Modal
        title="Import Bulk Expenses"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="mt-20"
      >
        <div>
          <input
            type="file"
            accept=".xls, .xlsx"
            onChange={handleFileChange}
            style={{ display: "block", marginBottom: "10px" }}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={handleSubmit}
            disabled={!file}
          >
            <UploadOutlined /> Upload
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ImportBulkExpenses;
