import React, { useState, useEffect } from "react";
import "../App.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import WeeklyExpense from "../components/WeeklyExpense";
import YearlyExpense from "../components/YearlyExpense";
import MonthlyExpense from "../components/MonthlyExpense";
import { Link, useParams } from "react-router-dom";
import { Select } from "antd";
import ExpenseListProjectDetail from "../components/ExpenseListProjectDetail";

const { Option } = Select;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "PKR":
      return "Rs";
    case "SAR":
      return "﷼";
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    case "AED":
      return "AED";
    default:
      return currency;
  }
};

const ProjectDetail = () => {
  const baseCurrency = localStorage.getItem("baseCurrency");
  const token = localStorage.getItem("token");
  const { selectedProject } = useParams();
  const [selectedDuration, setSelectedDuration] = useState("last12months");
  const [expense, setExpense] = useState({
    totalIncome: 0,
    totalExpense: 0,
    filteredExpenses: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [userProject, setUserProject] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isSubUser, setIsSubUser] = useState(true);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: userProfile } = await axios.get(
        `${API_BASE_URL}/auth/profile`,
        config
      );
      setUserProject("All");
      setIsSubUser(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          const { data: subUserProfile } = await axios.get(
            `${API_BASE_URL}/subUsers/subUserProfile`,
            config
          );
          setUserProject(subUserProfile.project || "");
          setUserRole(subUserProfile.role || "");
          setIsSubUser(true);

          if (subUserProfile.role === "admin") {
            setUserProject("All");
          }
        } catch (subUserError) {
          console.error(subUserError);
        }
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/projects/getProjectList`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        toast.error("Failed to load projects");
      }
    };
    fetchProjects();
    fetchUserProfile();
  }, []);

  const fetchFilteredExpenses = async (selectedProject, selectedDuration) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/expenses/filter`, {
        params: { selectedProject, selectedDuration },
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;
      if (data.filteredExpenses) {
        setExpense(data);
      } else {
        console.error("Invalid expense data format:", data);
        setExpense({ totalIncome: 0, totalExpense: 0, filteredExpenses: [] });
      }
    } catch (error) {
      console.error("Error fetching filtered expenses:", error);
      setError("Failed to load expense data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFilteredExpenses(selectedProject, "last12months");
  }, []);

  useEffect(() => {
    fetchFilteredExpenses(selectedProject, selectedDuration);
  }, [selectedProject, selectedDuration]);

  const handleRefresh = () => {
    fetchFilteredExpenses(selectedProject, "last12months");
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <div class="spinner"></div>
      </div>
    );
  if (error) return <p>Error: {error}</p>;
  return (
    <div className="flex flex-col gap-2 h-full bg-white rounded-xl py-3 px-4 border border-2 border-[#E9EAEB] space-y-2">
      <div className="flex justify-between">
        <div style={{ fontFamily: "Roboto, sans-serif" }}>
          <h1 className="font-bold text-lg md:text-xl">{selectedProject}</h1>
        </div>
        <div className="flex gap-2 items-center">
          <button
            onClick={handleRefresh}
            className="text-black border border-[#DCE3E3] px-3 py-1 rounded-md mb-4 md:mb-0"
          >
            <i class="fa-solid fa-arrow-rotate-right text-lg"></i>
          </button>
          <Link to="/panel/addExpense">
            <button className="bg-themeGradient text-white px-2 py-1 rounded-md mb-4 md:mb-0 flex items-center">
              <i className="fa-solid fa-plus text-lg"></i>
              <span className="ms-2">New Transaction</span>
            </button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 w-full text-center mb-4 font-customFont">
        <div className="border border-[#AFF0CC] bg-[#EDFFF5] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#006B31] text-md">Total Income</p>
          <p className="text-[#002410] text-3xl font-bold mt-1">
            {getCurrencySymbol(baseCurrency)}{" "}
            <span>{Math.floor(expense.totalIncome).toLocaleString()}</span>
            <span className="text-[#55AA55] text-xl">
              .{expense.totalIncome.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
        <div className="relative border border-[#FFE5E3] bg-[#FFF5EF] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#B51D1D] text-md">Total Expense</p>
          <p className="text-[#7C1A1D] text-3xl font-bold mt-1">
            {getCurrencySymbol(baseCurrency)}{" "}
            <span>{Math.floor(expense.totalExpense).toLocaleString()}</span>
            <span className="text-[#D65555] text-xl">
              .{expense.totalExpense.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
        <div className="border border-[#CCD7FF] bg-[#E5EBFF] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#002199] text-md">Total Net Income</p>
          <p className="text-[#000B33] text-3xl font-bold mt-1">
            {getCurrencySymbol(baseCurrency)}{" "}
            <span>
              {Math.floor(
                expense.totalIncome - expense.totalExpense
              ).toLocaleString()}
            </span>
            <span className="text-[#555588] text-xl">
              .
              {
                (expense.totalIncome - expense.totalExpense)
                  .toFixed(2)
                  .split(".")[1]
              }
            </span>
          </p>
        </div>
      </div>
      <div className="w-full rounded-lg border border-[#E5E7EB] shadow-sm p-3">
        <div className="flex justify-between">
          <div>
            <p className="font-semibold font-customFont">Overview</p>
          </div>
          <div className="flex gap-2 font-customFont">
            <Select
              defaultValue="last12months"
              required
              value={selectedDuration}
              onChange={(value) => setSelectedDuration(value)}
              className={`block w-full lg:w-60 rounded-md ${
                selectedDuration !== null ? "ring-1 ring-blue-400" : ""
              }`}
            >
              {(isSubUser && userRole === "Admin") || !isSubUser ? (
                <>
                  <Option value="currentweek">Last 7 Days</Option>
                  <Option value="last12months">Last 12 Months</Option>
                  <Option value="last6years">Last 6 Years</Option>
                </>
              ) : (
                <Option value={userProject}>{userProject}</Option>
              )}
            </Select>
          </div>
        </div>
        <div>
          {selectedDuration === "last6years" && (
            <YearlyExpense
              selectedProject={selectedProject}
              selectedDuration={selectedDuration}
            />
          )}
          {selectedDuration === "last12months" && (
            <MonthlyExpense
              selectedProject={selectedProject}
              selectedDuration={selectedDuration}
            />
          )}
          {selectedDuration === "currentweek" && (
            <WeeklyExpense
              selectedProject={selectedProject}
              selectedDuration={selectedDuration}
            />
          )}
        </div>
      </div>
      <div>
        <ExpenseListProjectDetail selectedProject={selectedProject} />
      </div>
    </div>
  );
};

export default ProjectDetail;
