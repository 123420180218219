import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "../App.css";
import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";


const AddExpense = () => {
  const token = localStorage.getItem("token");
  const baseCurrency = localStorage.getItem("baseCurrency");
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [project, setProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectCur, setProjectCur] = useState("");
  const [type, setType] = useState("");
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [userProject, setUserProject] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isSubUser, setIsSubUser] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/projects/getProjectList`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        toast.error("Failed to load projects");
      }
    };

    const currentDate = new Date().toISOString().split("T")[0];
    setDate(currentDate);

    const fetchUserProfile = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const { data: userProfile } = await axios.get(
          `${API_BASE_URL}/auth/profile`,
          config
        );
        setUserProject("All");
        setIsSubUser(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          try {
            const { data: subUserProfile } = await axios.get(
              `${API_BASE_URL}/subUsers/subUserProfile`,
              config
            );
            setUserProject(subUserProfile.project || "");
            setUserRole(subUserProfile.role || "");
            setIsSubUser(true);
          } catch (subUserError) {
            toast.error("Failed to fetch sub-user data");
            console.error(subUserError);
          }
        } else {
          toast.error("Failed to fetch user profile");
          console.error(error);
        }
      }
    };

    fetchProjects();
    fetchUserProfile();
  }, []);

  const fetchCurrencyRates = async () => {
    const url = `https://api.exchangerate-api.com/v4/latest/${baseCurrency}`;
  
    try {
      const response = await axios.get(url);
      return response.data.rates;
    } catch (error) {
      console.error("Error fetching currency rates:", error.response?.data || error.message);
      return null;
    }
  };
  

  const handleProjectChange = (selectedProject) => {
    const selectedProjectData = projects.find(
      (proj) => proj.project === selectedProject
    );
    if (selectedProjectData) {
      setProject(selectedProjectData.project);
      setProjectCur(selectedProjectData.projectCurrency);
    }
  };

  const handleImageChange = ({ fileList }) => {
    setFileList(fileList);
    if (fileList.length > 0) {
      setPreviewImage(URL.createObjectURL(fileList[0].originFileObj));
    } else {
      setPreviewImage("");
    }
  };

  const uploadImage = async (image) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "ExpenseTracker");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dvrkykhd0/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await response.json();
      return urlData.url;
    } catch (error) {
      console.error(error);
      toast.error("Image upload failed.");
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please log in to add an expense.");
      navigate("/signIn");
      return;
    }

    let imageUrl = "";
    if (fileList.length > 0) {
      imageUrl = await uploadImage(fileList[0].originFileObj);
      if (!imageUrl) return;
    }

    const rates = await fetchCurrencyRates();
    if (!rates) {
      toast.error("Failed to fetch currency rates.");
      return;
    }

    const projectCurrency = projectCur;
    const conversionRate = rates[projectCurrency] || 1;
    const baseCurrencyPrice = (Number(amount) / conversionRate).toFixed(2);

    const expenseData = {
      title,
      price: Number(amount),
      baseCurrencyPrice: baseCurrencyPrice,
      description,
      date,
      project,
      type,
      imagePath: imageUrl,
      projectCurrency: projectCur,
      conversionRate,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/expenses/addExpense`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(expenseData),
      });

      if (response.ok) {
        toast.success("Expense saved successfully!");
        setTitle("");
        setAmount("");
        setDescription("");
        setDate(new Date().toISOString().split("T")[0]);
        setProject("");
        setType("");
        setFileList([]);
        setTimeout(() => {
          navigate("/Panel/expenseList");
        }, 1000);
      } else {
        const errorText = await response.text();
        toast.error(`Failed to save expense: ${errorText}`);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while saving expense.");
    }
  };

  return (
    <div className="w-full mx-auto p-4 bg-white h-full rounded-lg font-customFont">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <div style={{ fontFamily: "Roboto, sans-serif" }}>
          <h1 className="font-bold text-lg md:text-lg">Add New Transaction</h1>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Title:
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Amount:
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Project:
                <select
                  value={project}
                  onChange={(e) => handleProjectChange(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  <option value="">Select Project</option>
                  {(isSubUser && userRole === "Admin") || !isSubUser ? (
                    <>
                      {projects.map((pro) => (
                        <option key={pro._id} value={pro.project}>
                          {pro.project}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option value={userProject}>{userProject}</option>
                  )}
                </select>
              </label>
            </div>
          </div>

          <div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Date:
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Image (Optional):
                <Upload
                  listType="picture"
                  fileList={fileList}
                  onChange={handleImageChange}
                  beforeUpload={() => false}
                  className="mt-1 block w-full py-0 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="mb-1 bg-transparent border-0 hover:!text-themeColor hover:!bg-transparent hover:text-themeColor2 focus:outline-none"
                  >
                    Upload Image
                  </Button>
                </Upload>
              </label>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-1">
                Transaction Type:
              </label>
              <div className="mt-1 block w-full px-3 py-1.5 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor">
                <label className="inline-flex items-center mr-4">
                  <input
                    type="radio"
                    value="Income"
                    checked={type === "Income"}
                    onChange={() => setType("Income")}
                    className="custom-radio"
                  />
                  <span className="ml-2">Income</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="Expense"
                    checked={type === "Expense"}
                    onChange={() => setType("Expense")}
                    className="custom-radio"
                  />
                  <span className="ml-2">Expense</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 text-center">
          <div className="mb-4">
            <label className="block text-gray-700 mb-2 text-left">
              Description (Optional):
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                rows="4"
              />
            </label>
          </div>
        </div>

        <div className="mt-10 text-center">
          <button
            type="submit"
            className="py-2 px-6 w-40 bg-themeGradient text-white font-semibold rounded-md shadow-sm hover:bg-themeColor2"
          >
            SAVE
          </button>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </form>
    </div>
  );
};

export default AddExpense;
