import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const getLast6Years = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 6 }, (_, i) => currentYear - i).reverse();
};

const YearlyExpense = ({ selectedProject, selectedDuration }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expenseData, setExpenseData] = useState([]);
  const [showIncome, setShowIncome] = useState(true);
  const [showExpense, setShowExpense] = useState(true);
  const [showProfit, setShowProfit] = useState(true);

  const fetchFilteredExpenses = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.get(`${API_BASE_URL}/expenses/filter`, {
        params: { selectedProject, selectedDuration },
        headers: { Authorization: `Bearer ${token}` },
      });

      const last6Years = getLast6Years();

      const groupedData = last6Years.reduce((acc, year) => {
        acc[year] = { income: 0, expense: 0, profit: 0 };
        return acc;
      }, {});

      response.data.filteredExpenses.forEach((expense) => {
        const expenseYear = new Date(expense.date).getFullYear();
        if (groupedData[expenseYear]) {
          if (expense.type === "Income") {
            groupedData[expenseYear].income += expense.baseCurrencyPrice;
          } else if (expense.type === "Expense") {
            groupedData[expenseYear].expense += expense.baseCurrencyPrice;
          }
          groupedData[expenseYear].profit = Math.max(
            groupedData[expenseYear].income - groupedData[expenseYear].expense,
            0
          );
        }
      });

      const formattedData = last6Years.map((year) => ({
        year,
        ...groupedData[year],
      }));

      setExpenseData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setError("Failed to load expense data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFilteredExpenses();
  }, [selectedProject, selectedDuration]);

  const handleIncomeChange = () => setShowIncome((prev) => !prev);
  const handleExpenseChange = () => setShowExpense((prev) => !prev);
  const handleProfitChange = () => setShowProfit((prev) => !prev);

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <div className="spinner"></div>
      </div>
    );

  if (error) return <p>Error: {error}</p>;

  const showGraph = showIncome || showExpense || showProfit;

  return (
    <div className="rounded-lg bg-white p-4 lg:h-full font-customFont">
      <div className="flex mb-4">
        <label className="flex items-center me-2">
          <input
            type="checkbox"
            checked={showIncome}
            onChange={handleIncomeChange}
            className="custom-radioIncome me-1"
          />
          <span className="text-black">Total Income</span>
        </label>
        <label className="flex items-center me-2">
          <input
            type="checkbox"
            checked={showExpense}
            onChange={handleExpenseChange}
            className="custom-radioExpense me-1"
          />
          <span className="text-black">Total Expense</span>
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={showProfit}
            onChange={handleProfitChange}
            className="custom-radioNetIncome me-1"
          />
          <span className="text-black">Total Net Income</span>
        </label>
      </div>

      {expenseData.length === 0 ? (
        <p>No expenses available for the selected duration.</p>
      ) : (
        <>
          {!showGraph ? (
            <p>Please select at least one metric to display.</p>
          ) : (
            <BarChart
              dataset={expenseData}
              xAxis={[{ scaleType: "band", dataKey: "year" }]}
              yAxis={[{ scaleType: "linear" }]}
              series={[
                showIncome && {
                  dataKey: "income",
                  valueFormatter: (value) => `$${value.toFixed(2)}`,
                  color: "#4CAF50",
                },
                showExpense && {
                  dataKey: "expense",
                  valueFormatter: (value) => `$${value.toFixed(2)}`,
                  color: "#FF5733",
                },
                showProfit && {
                  dataKey: "profit",
                  valueFormatter: (value) => `$${value.toFixed(2)}`,
                  color: "#3F51B5",
                },
              ].filter(Boolean)}
              layout="vertical"
              margin={{ right: 30, left: 60 }}
              height={350}
            />
          )}
        </>
      )}
    </div>
  );
};

export default YearlyExpense;
