import { Input } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const SignIn = ({ toggleForm }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState("User");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!email || !password) {
      toast.error("Please fill in all fields.");
      setIsLoading(false);
      return;
    }

    try {
      const endpoint =
        userType === "User" ? "/auth/signin" : "/subUsers/loginSubUsers";

      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();

        localStorage.setItem("token", data.token);

        if (data.baseCurrency) {
          localStorage.setItem("baseCurrency", data.baseCurrency);
        } else if (data.user?.baseCurrency) {
          localStorage.setItem("baseCurrency", data.user.baseCurrency);
        }

        const userId = userType === "User" ? data.user?._id : data.subUser?._id;
        if (userId) {
          localStorage.setItem("userId", userId);
        }

        toast.success("Sign in successful!");
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else {
        const errorData = await response.json();
        toast.error(`Sign in failed: ${errorData.message}`);
      }
    } catch (error) {
      toast.error("An error occurred during sign-in.");
      console.error("Sign-in error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-full flex items-center justify-center">
      <div className="max-w-md w-full bg-white py-6 px-8 rounded-2xl border-4 border-gray-200">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-2">
          Welcome back to{" "}
          <span className="font-bold text-themeColor">DG Expense</span>
        </h2>
        <p className="text-sm text-center text-gray-500 mb-6">
          Log in to track and control your expenses with ease.
        </p>
        <form onSubmit={handleSignIn}>
          <div className="mb-4">
            <label className="block text-black mb-2">Email</label>
            <input
              type="email"
              value={email}
              placeholder="Enter email address"
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
              required
            />
          </div>

          <div className="mb-4 relative">
            <label className="block text-black mb-2">Password</label>
            <Input.Password
              type={showPassword ? "text" : "password"}
              value={password}
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
              required
            />
          </div>

          <div className="mt-1 text-right">
            <span className="text-themeColor1 font-semibold text-sm">
              Forgot Password?
            </span>
          </div>

          <div className="flex justify-start mb-4 gap-4">
            <label>
              <input
                type="radio"
                value="User"
                checked={userType === "User"}
                onChange={() => setUserType("User")}
                className="custom-radio mx-1 cursor-pointer"
              />
              <span className="font-semibold text-themeColor cursor-pointer">
                Admin
              </span>
            </label>
            <label>
              <input
                type="radio"
                value="SubUser"
                checked={userType === "SubUser"}
                onChange={() => setUserType("SubUser")}
                className="custom-radio me-1 cursor-pointer"
              />
              <span className="font-semibold text-themeColor cursor-pointer">
                User
              </span>
            </label>
          </div>

          <button
            type="submit"
            className={`w-full py-2 px-4 bg-themeGradient cursor-pointer text-white font-semibold rounded-md shadow-md hover:bg-themeColor1 transition-colors ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? "Logging In..." : "Login"}
          </button>
        </form>
        {/* <div className="mt-4 text-center">
          <span className="hover:text-black">
            Don't have an account?{" "}
            <button
              onClick={toggleForm}
              className="text-themeColor font-semibold hover:text-[#dc2626]"
            >
              Sign up
            </button>
          </span>
        </div> */}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default SignIn;
